import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors as c } from '../../styles/colors';

export const Title = styled.h1`
  color: ${c.greenDark};
  font-size: 10rem;
  font-family: 'Zilla Slab';
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 15rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: 992px) {
    font-size: 5rem;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  text-align: center;
`;

export const Button = styled(Link)`
  display: inline-block;
  background-color: ${c.grayText};
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: ${c.grayPaper};
  padding: 1rem 3rem;
  border-radius: 8px;
  text-decoration: none;
  transition: all .15s linear;

  &:hover {
    background-color: ${c.blackText};
  }
`;