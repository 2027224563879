import React from "react";
// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container1200 } from '../components/Container1200';
// Images
import metaImage from '../images/icons/favicon-512.png'
// Style
import {
  Button,
  Buttons,
  Subtitle,
  Title
} from '../styles/pages-styles/404.styles';

const NotFoundPage = () => {

  return (
    <Layout>
      <SEO
        image={`https://www.ambitiousbeaver.com${metaImage}`}
        title="404: Not found"
      />
        <Container1200>
          <Title>404</Title>
          <Subtitle>Oops! We could not find your page.</Subtitle>
          <Buttons>
            <Button to={'/'}>Go Home</Button>
          </Buttons>
        </Container1200>
    </Layout>
  )
}

export default NotFoundPage;
